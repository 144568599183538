import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { JobPost } from "compositions";
import { Layout } from "containers";
import { getDescription } from "utils/form";

const TemplateJob = ({
  data: {
    job: {
      data: {
        companyDescription,
        companyImages,
        companyLogo,
        companyLogoUrl,
        companyMission,
        companyName,
        companyUrl,
        date,
        hiringDescription,
        hiringManager,
        hiringStartDate,
        id,
        jobApplication,
        jobCategory,
        jobDescription,
        jobRegions,
        jobTitle,
        jobType,
      },
    },
  },
}) => {
  const layoutProps = {
    // TODO: configure JobPosting schema type
    // - some props can be added based on category
    // - others need fields added to airtable
    jsonLd: {
      "@context": "https://schema.org",
      "@type": "JobPosting",
      "@id": id,
      // baseSalary: jobSalary,
      // jobBenefits,
      datePosted: date,
      description: jobDescription?.raw,
      // educationRequirements,
      employmentType: jobType,
      // experienceRequirements: jobRequirements,
      // incentiveCompensation,
      industry: jobCategory,
      // jobLocation: {
      //   "@type": "Place",
      //   "address": {
      //     "@type": "PostalAddress",
      //     "addressLocality": companyLocality,
      //     "addressRegion": companyRegion
      //   }
      // },
      // occupationalCategory,
      // qualifications: jobQualifications,
      // responsibilities,
      // salaryCurrency,
      // skills,
      title: jobTitle,
      // workHours,
    },
    seoProps: {
      title: `${companyName} ${jobTitle} | ${jobCategory} Jobs`,
      description: jobDescription?.raw,
      openGraph: {
        title: `${jobTitle} @ ${companyName}`,
        description: jobDescription?.raw,
        // TODO: add company images to images meta
        // images: [
        //   ...companyImages
        // ],
      },
    },
  };

  return (
    <Layout {...layoutProps}>
      <JobPost
        companyDescription={getDescription(companyDescription)}
        companyImages={
          companyImages && companyImages.length
            ? JSON.parse(companyImages)
            : null
        }
        companyLogo={
          !!companyLogo
            ? companyLogo?.localFiles[0]?.childImageSharp?.fluid?.src
            : companyLogoUrl
        }
        applyJob={true}
        companyMission={companyMission}
        companyName={companyName}
        companyUrl={companyUrl}
        hiringDescription={getDescription(hiringDescription)}
        hiringManager={hiringManager}
        hiringStartDate={hiringStartDate}
        jobApplication={jobApplication}
        jobCategory={jobCategory}
        jobDescription={getDescription(jobDescription)}
        jobRegions={jobRegions}
        jobTitle={jobTitle}
        jobType={jobType}
      />
    </Layout>
  );
};

TemplateJob.propTypes = {
  data: PropTypes.shape({
    job: PropTypes.shape({
      data: PropTypes.shape({
        companyDescription: PropTypes.shape({
          childMdx: PropTypes.shape({
            body: PropTypes.string,
          }),
        }),
        // companyImages: PropTypes.oneOfType([
        //   PropTypes.shape({
        //     localFiles: PropTypes.arrayOf(
        //       PropTypes.shape({
        //         childImageSharp: PropTypes.shape({
        //           fluid: PropTypes.shape({
        //             base64: PropTypes.string,
        //             aspectRatio: PropTypes.number,
        //             src: PropTypes.string,
        //             srcSet: PropTypes.string,
        //             sizes: PropTypes.string,
        //           }),
        //         }),
        //         name: PropTypes.string,
        //       })
        //     ),
        //   }),
        //   PropTypes.string,
        // ]),
        companyLogo: PropTypes.oneOfType([
          PropTypes.shape({
            localFiles: PropTypes.arrayOf(
              PropTypes.shape({
                childImageSharp: PropTypes.shape({
                  fluid: PropTypes.shape({
                    base64: PropTypes.string,
                    aspectRatio: PropTypes.number,
                    src: PropTypes.string,
                    srcSet: PropTypes.string,
                    sizes: PropTypes.string,
                  }),
                }),
                name: PropTypes.string,
              })
            ),
          }),
          PropTypes.string,
        ]),
        companyMission: PropTypes.string,
        companyName: PropTypes.string,
        companyUrl: PropTypes.string,
        hiringDescription: PropTypes.shape({
          childMdx: PropTypes.shape({
            body: PropTypes.string,
          }),
        }),
        hiringManager: PropTypes.string,
        hiringStartDate: PropTypes.string,
        id: PropTypes.string,
        jobApplication: PropTypes.string,
        jobCategory: PropTypes.oneOf([
          "Art",
          "Audio",
          "Design",
          "Development",
          "Social",
          "Video",
          "Writing",
        ]),
        jobDescription: PropTypes.shape({
          childMdx: PropTypes.shape({
            body: PropTypes.string,
          }),
        }),
        jobRegions: PropTypes.arrayOf(
          PropTypes.oneOf([
            "Africa",
            "Anywhere",
            "Asia",
            "Canada",
            "Europe",
            "South America",
            "US",
          ])
        ),
        jobTitle: PropTypes.string,
        jobType: PropTypes.oneOf(["Contract", "Full-time", "Part-time"]),
      }),
    }),
  }),
};

export const jobByIdQuery = graphql`
  query JobById($id: String!) {
    job: airtable(data: { id: { eq: $id } }, table: { eq: "Jobs" }) {
      data {
        companyDescription {
          childMdx {
            body
          }
          raw
        }
        # companyImages {
        #   localFiles {
        #     childImageSharp {
        #       fluid(maxWidth: 1280, quality: 90) {
        #         ...GatsbyImageSharpFluid_tracedSVG
        #       }
        #     }
        #   }
        # }
        # NOTE: use this query if companyLogo is an attachment type
        companyLogo {
          localFiles {
            childImageSharp {
              fluid(maxWidth: 480, quality: 90) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
        companyLogoUrl
        companyImages
        companyMission
        companyName
        companyUrl
        date
        hiringDescription {
          childMdx {
            body
          }
          raw
        }
        hiringManager
        hiringStartDate
        id
        jobApplication
        jobCategory
        jobDescription {
          childMdx {
            body
          }
          raw
        }
        jobRegions
        jobTitle
        jobType
      }
    }
  }
`;

export default TemplateJob;
